import "./Assets/css/App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ActivityLogs from "./pages/activitylogs";
import DataCenters from "./pages/datacenters";
import HardwareTest from "./pages/hardwaretest";
import WriteOperations from "./pages/writeoperations";
import ScheduleVisit from "./pages/schedulevisit";
import AuditLog from "./pages/auditlog";
import UpcomingShipments  from "./pages/upcomingshipments";
import Todo from "./pages/todos";
import TicketDetails from "./pages/ticketdetails";
import Profile from "./pages/profile";
import * as API from "./api";
import { useState, React, useEffect } from "react";
import LoadingIcon from "./components/Spinners/Loading";
import { useVerifyUserMutation, useLoginUserMutation, useLazyGetUserQuery  } from "./services/user";
import { getUserDetails } from "./api";
import * as FullStory from '@fullstory/browser';
import { Alert } from "antd";
import RackDetails from "./pages/rackdetails";
import SearchPage from "./pages/searchpage";

function App() {
  const url = new URL(window.location.href);
  const [token, setToken] = useState(url.searchParams.get("token"));
  const id_token = url.searchParams.get("id_token");
  const [allowLogin, setAllowLogin] = useState(null);
  const [spinner, startSpinner] = useState(null);
  const [validateToken, result] = useVerifyUserMutation();
  const [triggerLogin, loginResult] = useLoginUserMutation();
  const [errorMsg, setErrormsg] = useState(false);
  const userDetails = getUserDetails();
  const [triggerLazyGetUser, {data:userLazyData, error: userLazyError, isLoading: isUserLazyLoading, isFetching:isUserLazyFetching}] = useLazyGetUserQuery();
  const [isInfoStored, setIsInfoStored] = useState(false)

  let isImpersonated = localStorage.getItem("isImpersonated") === "true" ? true : false;

  useEffect(() => {
    loadUser();
    FullStory.init({ orgId: 'o-1EMVFS-na1' });
    if(userDetails?.sub) {
      if(!localStorage.getItem("userDetails") || localStorage.getItem("userDetails") === "null"){
        triggerLazyGetUser(userDetails.sub)
        setIsInfoStored(false)
      }
      FullStory.identify(userDetails.extension_ContactID, {
        displayName: userDetails.name,
        email: userDetails.email,
      });
    }
  
    // console.log(`NODE ENV - ${process.env.NODE_ENV}`);
    console.log(`REACT_APP_FT_PORTAL_URL Value - ${process.env.REACT_APP_FT_PORTAL_URL}`);
  }, []);

  useEffect(() => {
    const version = localStorage.getItem('REACT_APP_FTPORTAL_APP_VERSION');
      const versionEnv = process.env.REACT_APP_FTPORTAL_APP_VERSION;
      console.log(`version from storage - ${version}`)
      console.log(`version from env - ${versionEnv}`)
      if (version !== versionEnv) {
        localStorage.setItem('REACT_APP_FTPORTAL_APP_VERSION', process.env.REACT_APP_FTPORTAL_APP_VERSION);
        localStorage.removeItem('ft-portal-col');
      }    
  }, [])

  useEffect(()=>{
    if(!isUserLazyFetching && userLazyData?.data){
      localStorage.setItem("userDetails", userLazyData.data.mobilePhone || null)
      setIsInfoStored(true)
    }
  }, [userLazyData, isUserLazyFetching])

 
  async function callValidateToken(token) {
    try {
      if (token) {
        const resp = await validateToken(token).unwrap();
        if (resp && resp.success) return token;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function login() {
    console.log("triggerring login");
    try {
      const resp = await triggerLogin().unwrap();
      if (resp && resp.success && resp.data) {
        const redirectURL = resp.data;
        console.log(`redirect url - ${redirectURL}`);
        window.location.href = redirectURL;
      }
    } catch (error) {
      console.log(error);
      setErrormsg(true);
      return null;
    }
  }

  const loadUser = async () => {
    console.log("logging in");
    if (token) {
      startSpinner(true);
      const validate = await callValidateToken(token);
      if (validate) {
        console.log("token is valid");
        API.setToken(token, id_token);
        setToken(token);
        setAllowLogin(true);
        let lastpath = API.getLastPath();
        if (window.location.pathname === "/") {
          API.setLastPath("/");
          console.log(`old A - ${window.location.href}`);
          console.log(`session - ${sessionStorage.getItem("lastPath")}`);
          window.location.href = "/datacenters";
        } else if (
          lastpath !== "/" &&
          lastpath !== null &&
          lastpath !== window.location.href
        ) {
          console.log(`old b - ${lastpath}`);
          window.location.href = lastpath;
        }
        startSpinner(false);
      } else {
        startSpinner(false);
        API.setMessage("Invalid Request", "error");
        API.clearData();
        setToken(null);
        await login();
      }
      return;
    }
    console.log(`using old token`);
    const old_token = API.getToken();
    const valid = await callValidateToken(old_token);
    if (old_token && valid) {
      console.log("old token is valid");
      setToken(old_token);
      setAllowLogin(true);
      let lastpath = API.getLastPath();
      if (window.location.pathname === "/") {
        API.setLastPath("/");
        console.log(`A - ${window.location.href}`);
        console.log(`session - ${sessionStorage.getItem("lastPath")}`);
        window.location.href = "/datacenters";
      } else if (
        lastpath !== "/" &&
        lastpath !== null &&
        lastpath !== window.location.href
      ) {
        console.log(`B - ${lastpath}`);
        // window.location.href = lastpath;
      }
    } else {
      console.log("old token is not valid");
      await login();
    }
    return;
  };
  const downTimeMessage = (<><b>Scheduled Maintenance Notice: </b>Please note that the Field Tech portal will be unavailable between 06:00 EDT / 10:00 UTC to 14:00 EDT / 18:00 UTC on Saturday, February 22nd due to a quarterly data center maintenance performed by Akamai ITOC that will impact the servers hosting this application. Please reach out to support in case of any queries/concerns.</>)
  return (
    <div className="wrapper">
      {errorMsg && (
        <Alert 
        message="Network Error"
        description="Please try again later. If the issue persists, please contact dl-ftportal-help@akamai.com"
        type="error"
        showIcon/>
      )}
      {spinner && <LoadingIcon tip="Authenticating..." />}
      {allowLogin && (
        <>
          {/* <Alert message={downTimeMessage} type="warning" showIcon closable /> */}
          <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard isImpersonated={isImpersonated} />}>
              <Route index path="datacenters" element={<DataCenters isImpersonated={isImpersonated} isInfoStored={isInfoStored}/>}></Route>
              <Route
                path="datacenters/:data_center_id/todos"
                element={<Todo isImpersonated={isImpersonated} type="todos"/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/todos/:ticket_id"
                element={<TicketDetails isImpersonated={isImpersonated} type="todos"/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/rmas/:ticket_id"
                element={<TicketDetails isImpersonated={isImpersonated} type="rmas"/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/rmas"
                element={<Todo isImpersonated={isImpersonated} type="rmas"/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/schedule-visit"
                element={<ScheduleVisit isImpersonated={isImpersonated}/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/audit-log"
                element={<AuditLog isImpersonated={isImpersonated}/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/upcoming-shipments"
                element={<UpcomingShipments isImpersonated={isImpersonated}/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/unknown-racks/"
                element={<RackDetails type='unknown' isImpersonated={isImpersonated}/>}
              ></Route>
              <Route
                path="datacenters/:data_center_id/rack/:rack_number"
                element={<RackDetails type='known' isImpersonated={isImpersonated}/>}
              ></Route>
              <Route
                path="datacenters/search"
                element={<SearchPage isImpersonated={isImpersonated}/>}
              ></Route>
              <Route path="profile" element={<Profile isImpersonated={isImpersonated}/>}></Route>
              <Route path="activitylogs" element={<ActivityLogs isImpersonated={isImpersonated}/>}></Route>
              <Route path="hardwaretest" element={<HardwareTest isImpersonated={isImpersonated}/>}></Route>
              <Route path="writetosiebel" element={<WriteOperations isImpersonated={isImpersonated}/>}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
        </>
        
      )}
    </div>
  );
}

export default App;
